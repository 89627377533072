<script>
	import { asyncRoutes , asyncRoutes_children } from '@/router/index.js';
	export default {
		created() {
			const { params, query } = this.$route;
			const { pathMatch } = params;
			if( this.user_info == '' ){
				this.$router.replace({
					path : '/login'
				});
			}else{
				this.initialization( pathMatch == '/' ? '/systemManage/userManage' : pathMatch );
			}
		},
		render: function(h) {
			return h() // avoid warning message
		},
		methods:{
			initialization( path ){
				this.$api.initialization( this.user_info.userName ).then( res => {
					if( res.code == 200 ){
						this.$message({
							type: 'success',
							message: '登录成功！'
						});
						let menuList = this.$common.menuList( res.data.menuList );
						res.data.user.menuList = res.data.menuList;
						if( res.data.user.isIndividual == 1 ){
							res.data.user.alarmMessageList = res.data.alarmMessageList;
							res.data.user.is_read = false;
						}
						this.$vuex( 'menuList' , menuList[0] );
						this.$vuex( 'user_info' , res.data.user );
						this.$router.push( { path : path } );
					}else{
						this.$router.replace({
							path : '/login'
						});
					}
				});
			},
		},
	}
</script>
